<template>
    <div class="sell-to-sell">
        <van-form @submit="sellTransSellSubmit">
            <ul class="info-card">
                <li>                
                    <van-row>
                        <van-col span="24"><h3>钱包地址</h3></van-col>
                    </van-row>
                    <van-row>
                        <van-col span="24">
                            <van-field class="input-border" v-model="addr" name="addr" placeholder="请粘贴钱包地址" :rules="[{ required: true, message: '钱包地址不能为空' }]" />
                        </van-col>
                    </van-row>
                </li>
            </ul>
            <ul class="info-card">
                <li>                
                    
                    <van-cell is-link :title="isSellOptions[currentOption].name" :value="isSellOptions[currentOption].amount" @click="isSellVisible = true" />
                    <van-action-sheet v-model:show="isSellVisible" :actions="isSellOptions" @select="changeIsSell" />
                    <van-field v-model="isSell" name="isSell" type="hidden" :rules="[{ required: true, message: '请选择是否可售' }]" />
                       
                </li>
            </ul>
            <ul class="info-card">
                <li>                
                    <van-row>
                        <van-col span="24"><h3>金额</h3></van-col>
                    </van-row>
                    <van-row>
                        <van-col span="24">
                            <van-field class="input-border" v-model="amt" name="amt" label="￥" label-width="15" placeholder="请输入转账金额"  :rules="[{ required: true, message: '金额不能为空' }]" />
                        </van-col>
                    </van-row>
                </li>
            </ul>
            <ul class="info-card">
                <li>                
                    <van-row>
                        <van-col span="24"><h3>支付密码</h3></van-col>
                    </van-row>
                    <van-row>
                        <van-col span="24">
                            <van-field class="input-border" type="password" v-model="drawpass" name="drawpass" icon="plus" label-width="15" placeholder="请输入支付密码" autocomplete="new-password" :rules="[{ required: true, message: '支付密码不能为空' }]" />
                        </van-col>
                    </van-row>
                </li>
            </ul>
            <div class="submit-btn">
                <van-button block type="primary" native-type="submit">确定转账</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import { sellTransSell } from '@/api'
import md5 from 'js-md5'

export default {
    name: 'SellTransSell',
    setup() {
        const state = reactive({
            addr: '',
            isSell: null,
            amt: null,
            drawpass: '',
            isSell: null,
            isSellOptions: [
                { name: '选择余额', value: null, amount: '' },
                { name: '可售余额', value: 1, amount: 0 },
                { name: '不可售余额', value: 0, amount: 0 },
            ],
            currentOption: 0,
            isSellVisible: false         
        })
        const store = useStore()

        onMounted(() => {            
            const userInfo = store.state.userInfo
            const total = userInfo.ACC_BAL
            const sale = userInfo.ACC_BAL_SALE
            state.isSellOptions[1].amount = sale
            state.isSellOptions[2].amount = total - sale
        })

        // 转账提交
        const sellTransSellSubmit = params => {
            const {addr, isSell, amt, drawpass} = params
            sellTransSell({addr, isSell, amt, drawpass: md5(drawpass)}).then(res => {
                if (res.code === 200) {
                    Toast.success(res.message)
                    state.amt = null
                } else {
                    Toast.fail(res.message);
                }                
                state.drawpass = ''
            }).catch(err => {
                Toast.fail(`转账失败：${err}`);
            })
        }

        const changeIsSell = (item, i) => {
            state.currentOption = i
            state.isSellVisible = false
            state.isSell = item.value
        }

        return {
            ...toRefs(state),
            sellTransSellSubmit,
            changeIsSell
        }
    },
    beforeRouteEnter:function(to, from, next) {
        if (from.name !== 'Home') next(from.path)        
        const params = to.params || {}
        if (params.sellTrans == 1) {
            next()
        } else {
            next('/Home')
        }
	}
}
</script>

<style lang="scss" scoped>
.van-cell::after {
    border: 0
}
.van-dropdown-menu__bar {
    border:#f00 1px solid !important;
    background-color: #0f0;
    box-shadow: none;
}
.van-cell {
    padding: 0;
}
.input-border { 
    border-bottom:#eee 1px solid;
}
.info-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 15px;
    margin: h(15) w(10);
    li {
        border-bottom: #eee 0px solid;
        padding: 5px 0;
        h3 {
            display: block;
            padding-bottom: 10px;
        }
        .testCopy {
            padding: 0 10px;
        }
        &:last-child{
            border: 0
        }
    }
}
.sell-to-sell {
    background-color: #eee;
    .submit-btn{
        padding: h(15) w(10);
    }
}
</style>
